import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bottomBarActions } from "../../store/bottomBarSlice";
import { loginActions } from "../../store/loginSlice";
import "./location-search.css";
import Script from "react-load-script";
import { locationActions } from "../../store/locationSlice";
import { API_TEST, FREE_DILIVERY_DISTANCE_RANGE, MINIMUM_AMOUNT_MAX_RANGE, MINIMUM_AMOUNT_MIN_RANGE, ORDER_DISTANCE_RANGE, ORDER_DISTANCE_RANGE_MIN, PIDGE_API_TOKEN, PIDGE_API_URL, SHADOWFAX_API_TOKEN, SHADOWFAX_API_URL } from "../../store/constants";
import { cartAction } from "../../store/cartSlice";
import ReactGA from "react-ga4";
import mixpanel from "mixpanel-browser";
import { addTempUser } from "../../store/loginActions";
import { LocationOn, LocationOnOutlined } from "@material-ui/icons";
import BackBtnSvg from "../../assets/ReactSvg/BackBtnSvg";
import { uniqBy } from "lodash";
import axios from "axios";
import { extractPincode } from "../../utils/helper";
// import clevertap from "clevertap-web-sdk";

const LocationSearch = (props) => {
  const { locationSelector } = props
  const [query, setQuery] = useState("");
  const [location, setLocation] = useState(null);
  // const deliveryDetails = useSelector(({ auth }) => auth.deliveryDetails);
  const dispatch = useDispatch();
  const { isAddAddressActive } = useSelector((state) => state.location);
  const isServiceable = useSelector((state) => state.auth.isServiceable);
  const user_location = useSelector(({ auth }) => auth.location);
  const chefList = useSelector((state) => state.chef.chefList);
  const defaultChefLocation = chefList.find((c) => c?.chefLocation)?.chefLocation;

  useEffect(() => {
    // ReactGA.send({ hitType: "pageview", page: "/home" });
    ReactGA.event({
      category: "tab",
      action: "location-search-clicked",
    });

    mixpanel.track("location-selector");
    // clevertap.event.push("location-selector");
  }, []);
  
  const sourceLocation = defaultChefLocation || {
    lat: 19.116606,
    lng: 72.8854982,
  };

  useEffect(() => {
    if (location !== null) {
      dispatch(
        loginActions.setCoords({
          lat: location.lat,
          lng: location.lng,
        })
      );
      dispatch(loginActions.setLocation(location.area));
      
      distanceMatixHandler(location.lat, location.lng);
      const tempUser = {
        mpUserId: localStorage.getItem("mpUserId"),
        location: {
          lat: location?.lat,
          lng: location?.lng,
          locationName: location?.area,
        },
      };
      dispatch(addTempUser(tempUser));
    }
  }, [location]);

  const switchTab = (tabName) => {
    dispatch(bottomBarActions.loadTab({ tabName: tabName }));
    if (props.from) {
      dispatch(bottomBarActions.updateFrom({ from: "" }));
    }
  };

  const [loginState, setLoginState] = useState({
    area: "",
  });

  const autocompleteRef = useRef(null);
  const distanceMatrixRef = useRef(null);

  const checkShadowfaxDeliveryServiceable = async (requestBody) => {
    try {
      const token = SHADOWFAX_API_TOKEN; // Your token
      const apiUrl = `${SHADOWFAX_API_URL}serviceability/`;
  
      const config = {
        headers: {
          Authorization: `${token}`
        }
      };
  
      const response = await axios.post(apiUrl, requestBody, config);
      const deliveryValue = response.data;
      if(deliveryValue.is_serviceable){
        dispatch(loginActions.setAvailableDeliveryOptions({type: "SHADOWFAX", payload: deliveryValue} ));
      }else{
        dispatch(loginActions.setAvailableDeliveryOptions({type: "SHADOWFAX", payload: {}} ));
      }
    } catch (error) {
      dispatch(loginActions.setAvailableDeliveryOptions({type: "SHADOWFAX", payload: {}} ));
    }
  };

  const checkDeliveryServiceable = async (requestBody) => {
    try {
      const token = PIDGE_API_TOKEN; // Your token
      const apiUrl = `${PIDGE_API_URL}/v1.0/store/channel/vendor/quote`;

      const config = {
        headers: {
          Authorization: `${token}`
        }
      };

      const response = await axios.post(apiUrl, requestBody, config);
      const deliveryOptions = response.data.data.items.filter(response =>
        response.pickup_now &&
        response.quote.eta.pickup &&
        response.quote.eta.drop
      );;
      dispatch(loginActions.setAvailableDeliveryOptions({type: "PIDGE", payload: deliveryOptions} ));
    } catch (error) {
      dispatch(loginActions.setAvailableDeliveryOptions({type: "PIDGE", payload: []}));
    }
  };

  const checkPorterDeliveryServiceable = async (porterBody) => {
    try {
      const apiUrl = `${API_TEST}porter/orders/get_quote`;
  
      const response = await axios.post(apiUrl, porterBody);
      const deliveryOptions = response.data.data.vehicles.filter(response =>
        response.type === "2 Wheeler"
      );;
      dispatch(loginActions.setAvailableDeliveryOptions({type: "PORTER", payload: deliveryOptions} ));
    } catch (error) {
      dispatch(loginActions.setAvailableDeliveryOptions({type: "PORTER", payload: []}));
    }
  };

  const distanceMatixHandler = (lat, lng) => {
    const uniqKitchenData = uniqBy(chefList, obj => obj.kitchenId);
    uniqKitchenData.filter((a) => a.kitchenId).map((kitchen) => {
      const kitchenId = kitchen.kitchenId;
      const chefLocation = kitchen?.chefLocation;
      distanceMatrixRef.current = new google.maps.DistanceMatrixService();
      distanceMatrixRef.current.getDistanceMatrix(
        {
          origins: [chefLocation],
          destinations: [
            {
              lat: lat,
              lng: lng,
            },
          ],
          travelMode: "DRIVING",
        },
        (res, status) => {
          if (status !== "OK") {
            console.log("Unable to fetch...");
          } else {
            let delivery_distance = res.rows[0].elements[0].distance.value;
            let delivery_duration = res.rows[0].elements[0].duration;
            let deliveryObject = {
              delivery_distance,
              delivery_duration,
              kitchenLocation: chefLocation
            };

            dispatch(loginActions.setDeliveryDetails({ kitchenId, payload: deliveryObject }));
            let deliverydeliveryDistanceInKMS = Math.round(
              delivery_distance / 1000
            );
            const requestBody = {
              "pickup": {
                "coordinates": {
                  "latitude": chefLocation.lat,
                  "longitude": chefLocation.lng
                },
                "pincode": extractPincode(res.originAddresses[0])
              },
              "drop": [
                {
                  "ref": "4324324",
                  "location": {
                    "coordinates": {
                      "latitude": lat,
                      "longitude": lng
                    },
                    "pincode": extractPincode(res.destinationAddresses[0])
                  },
                  "attributes": {
                    "cod_amount": 0,
                    "weight": 500,
                    "volumetric_weight": 225
                  }
                }
              ]
            }

            const porterBody = {
              "pickup_details": {
                  "lat": chefLocation.lat,
                  "lng": chefLocation.lng
              },
              "drop_details": {
                  "lat": lat,
                  "lng": lng
              },
              "customer": {
                  "name": "Ashwin",
                  "mobile": {
                      "country_code": "+91",
                      "number": "9664257767"
                  }
              }
          };

          const srequestBody = {
            pickup_details: {
              building_name: "",
              latitude: chefLocation.lat,
              longitude: chefLocation.lng,
              address: res.originAddresses[0]
            },
            drop_details: {
              building_name: "",
              latitude: lat,
              longitude: lng,
              address: res.destinationAddresses[0]
            }
          };

            checkDeliveryServiceable(requestBody)
            checkShadowfaxDeliveryServiceable(srequestBody)
            checkPorterDeliveryServiceable(porterBody)
            if (deliverydeliveryDistanceInKMS > ORDER_DISTANCE_RANGE) {
              dispatch(loginActions.setIsServiceable(false));
              dispatch(loginActions.setSelectedAddress(null));
              dispatch(loginActions.setIsServiceableAlert(true));
              dispatch(loginActions.setUnserviceableModalOnCart(true));
              dispatch(loginActions.setMinimumAmount(0));
            } else if (deliverydeliveryDistanceInKMS > ORDER_DISTANCE_RANGE_MIN &&
              deliverydeliveryDistanceInKMS <= ORDER_DISTANCE_RANGE) {
              dispatch(loginActions.setIsServiceable(true));
              dispatch(loginActions.setSelectedAddress(null));
              dispatch(cartAction.setIsAddressOptionsVisible(true));
              dispatch(loginActions.setIsServiceableAlert(false));
              dispatch(loginActions.setFarAwayModalOnCart(true));
              dispatch(loginActions.setMinimumAmount(MINIMUM_AMOUNT_MAX_RANGE));
            } else if (deliverydeliveryDistanceInKMS > FREE_DILIVERY_DISTANCE_RANGE &&
              deliverydeliveryDistanceInKMS < ORDER_DISTANCE_RANGE_MIN) {
              dispatch(loginActions.setIsServiceable(true));
              dispatch(loginActions.setSelectedAddress(null));
              dispatch(cartAction.setIsAddressOptionsVisible(true));
              dispatch(loginActions.setIsServiceableAlert(false));
              dispatch(loginActions.setMinimumAmount(MINIMUM_AMOUNT_MIN_RANGE));
            } else {
              dispatch(loginActions.setIsServiceable(true));
              dispatch(loginActions.setSelectedAddress(null));
              dispatch(cartAction.setIsAddressOptionsVisible(true));
              dispatch(loginActions.setIsServiceableAlert(false));
              dispatch(loginActions.setMinimumAmount(0));
            }
          }
        }
      );
    })
  };

  const handleScriptLoad = () => {
    const options = {
      fields: ["formatted_address", "geometry", "name", "address_component"],
      componentRestrictions: { country: "In" },
      strictBounds: false,
      //types: ["geocode"],
    };
    // Initialize Google Autocomplete
    /*global google*/ // To disable any eslint 'google not defined' errors
    autocompleteRef.current = new google.maps.places.Autocomplete(
      document.getElementById("searchAutoComplete"),
      options
    );

    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components and formatted
    // address.
    autocompleteRef.current.setFields([
      "address_components",
      "formatted_address",
    ]);

    // Fire Event when a suggested name is selected
    autocompleteRef.current.addListener("place_changed", function () {
      const addressObject = autocompleteRef.current.getPlace();
      if (addressObject && addressObject.geometry) {
        const locationObject = {
          lat: addressObject.geometry.location.lat(),
          lng: addressObject.geometry.location.lng(),
          area: addressObject.formatted_address,
        };
        setLocation(locationObject);
      }

      setQuery(addressObject ? addressObject.formatted_address : "");
      dispatch(loginActions.setIsLocationEnabled());
      props.from ? switchTab(props.from) : isAddAddressActive ? switchTab("addressForm") : switchTab("chef");
      dispatch(locationActions.setIsAddAddressActive(false));
    });
  };
  if (!locationSelector) return <></>
  return (
    <>
      <Script
        url="https://maps.googleapis.com/maps/api/js?key=AIzaSyDSrNgyDHSkqDzUMNIJEbsLt3u_q1RbjYw&libraries=places"
        onLoad={handleScriptLoad}
      />

      <div className="auto_search_wrapper">
        <div
          className="location-search_back-btn"
          onClick={() => {
            props.from ? switchTab(props.from) : isAddAddressActive ? switchTab("addressForm") : switchTab("chef");
            dispatch(locationActions.setIsAddAddressActive(false));
          }}>
          <BackBtnSvg />
        </div>
        <h6>Enter your location</h6>
      </div>
      <label htmlFor="searchAutoComplete" className="location-search_input">
        <LocationOnOutlined />
        <input
          id="searchAutoComplete"
          type="text"
          name="area"
          autoFocus
          onChange={({ target }) => {
            setQuery("");
            setLoginState({
              ...loginState,
              [target.name]: target.value,
            });
          }}
          placeholder="Eg. enter area, street name..."
          value={query ? query : loginState.area}
        />
      </label>
    </>
  );
};

export default LocationSearch;
