import React, {Component} from 'react';
import './splash-screen.css';
import CheckoutSkeleton from './cart/CheckoutSkeleton';

function LoadingMessage() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const fromScreen = urlParams.get('fromScreen');
  const isCheckoutCancel = fromScreen === "checkoutCancel";
  if(isCheckoutCancel){
    return (
      <CheckoutSkeleton />
    );
  }
  return (
    <div className="splash-screen">
      <img src="cuirato-main.png" alt="loading..." />
    </div>
  );
}

function withSplashScreen(WrappedComponent) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true,
      };
    }

    async componentDidMount() {
      try {
        setTimeout(() => {
          this.setState({
            loading: false,
          });
        }, 1500)
      } catch (err) {
        console.log(err);
        this.setState({
          loading: false,
        });
      }
    }

    render() {
      // while checking user session, show "loading" message
      if (this.state.loading) return LoadingMessage();

      // otherwise, show the desired route
      return <WrappedComponent {...this.props} />;
    }
  };
}

export default withSplashScreen;