import React from 'react'
import ContentLoader from 'react-content-loader'

const CheckoutSkeleton = props => {
  const screenWidth = window.innerWidth; // Dynamic screen width

return (
  <ContentLoader
    speed={2}
    width={screenWidth} // Set width to full screen width
    height={900} // Adjust height to accommodate two sets of content
    viewBox={`0 0 ${screenWidth} 900`} // Adjust the viewBox height as well
    backgroundColor="#f5f5f5"
    foregroundColor="#ebebeb"
    {...props}
  >
    <rect x="79" y="20" rx="0" ry="0" width="0" height="1" />
    <rect x="4" y="1" rx="0" ry="0" width="3" height="600" />
    <rect x="4" y="598" rx="0" ry="0" width={screenWidth} height="3" />
    <rect x={screenWidth - 135} y="596" rx="0" ry="0" width="5" height="3" />
    <rect x="5" y="1" rx="0" ry="0" width={screenWidth} height="3" />
    <rect x={screenWidth - 3} y="1" rx="0" ry="0" width="3" height="600" />
    <rect x="5" y="60" rx="0" ry="0" width={screenWidth} height="3" />
    <rect x="22" y="20" rx="0" ry="0" width={screenWidth * 0.43} height="23" />
    <rect x="35" y="76" rx="4" ry="4" width={screenWidth * 0.27} height="9" />
    <rect x={screenWidth - 30} y="22" rx="4" ry="4" width="18" height="18" />
    <rect x={screenWidth * 0.63} y="76" rx="4" ry="4" width={screenWidth * 0.27} height="9" />
    <rect x={screenWidth * 0.5} y="63" rx="0" ry="0" width="2" height="44" />
    <rect x="6" y="104" rx="0" ry="0" width={screenWidth * 0.47} height="3" />
    <rect x={screenWidth * 0.5} y="106" rx="0" ry="0" width={screenWidth * 0.47} height="1" />
    <rect x="28" y="127" rx="4" ry="4" width={screenWidth * 0.82} height="31" />
    <rect x="62" y="188" rx="4" ry="4" width={screenWidth * 0.49} height="19" />
    <circle cx="39" cy="197" r="10" />
    <circle cx="39" cy="247" r="10" />
    <circle cx="39" cy="297" r="10" />
    <circle cx="39" cy="347" r="10" />
    <rect x="64" y="237" rx="4" ry="4" width={screenWidth * 0.49} height="19" />
    <rect x="65" y="287" rx="4" ry="4" width={screenWidth * 0.49} height="19" />
    <rect x="64" y="337" rx="4" ry="4" width={screenWidth * 0.49} height="19" />
    
    {/* Second set of content (adjust y values to space them out) */}
    <rect x="62" y="388" rx="4" ry="4" width={screenWidth * 0.49} height="19" />
    <circle cx="39" cy="397" r="10" />
    <circle cx="39" cy="447" r="10" />
    <circle cx="39" cy="497" r="10" />
    <circle cx="39" cy="547" r="10" />
    <rect x="64" y="437" rx="4" ry="4" width={screenWidth * 0.49} height="19" />
    <rect x="65" y="487" rx="4" ry="4" width={screenWidth * 0.49} height="19" />
    <rect x="64" y="537" rx="4" ry="4" width={screenWidth * 0.49} height="19" />
  </ContentLoader>
);
}

export default CheckoutSkeleton